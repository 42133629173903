<template>
  <div
    class="layout-logo"
    :style="logoStyle"
  >
    <img
      :src="portalLogo.imageUrl"
      :alt="alt"
      @error="onImageError"
    >
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import brokenImageMixin from '@/mixins/broken-image';

export default {
  name: 'LayoutLogo',

  mixins: [brokenImageMixin],

  props: {
    alt: {
      type: String,
      default: 'Логотип компании',
    },

    width: {
      type: Number,
      default: null,
    },

    height: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapGetters('system', ['portalLogo']),
    logoStyle() {
      return {
        width: `${this.width || this.portalLogo.width}px`,
        height: `${this.height || this.portalLogo.height}px`,
      };
    },
  },
};
</script>

<style lang="scss">
.layout-logo {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
